// 引入组件
import Login from "../views/login";
import Home from "../views/home";
import Detail from "../views/detail";
import Car from "../views/car";
import My from "../views/my";
import All from "../views/all";

// 注册路由数组
const routes = [
  {
    // 登录页默认/
    path: "/",
    element: <Login></Login>,
  },
  {
    // 首页
    path: "/home",
    element: <Home></Home>,
  },
  {
    // 详情页
    path: "/detail",
    element: <Detail></Detail>,
  },
  {
    // 购物车
    path: "/car",
    element: <Car></Car>,
  },
  {
    // 我的
    path: "/my",
    element: <My></My>,
  },
  {
    // 全部订单
    path: "/all",
    element: <All></All>,
  }
];
// 导出
export default routes;