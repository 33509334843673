import myback from '../assets/login/myback.png'; // 导入图片
// src/components/BottomNav.js
import React, { useEffect, useState } from 'react';
import add from '../assets/login/add.png'; // 导入图片
import {
    Form,  // 导入 Form 组件
    Stepper,  // 导入 Stepper 组件
    Toast,  // 导入 Toast 提示组件
} from 'antd-mobile';

import { useNavigate } from 'react-router-dom';
import homes from '../css/home.module.css'; // 导入 CSS 文件
import mys from '../css/my.module.css'; // 导入 CSS 文件
import cars from '../css/car.module.css'; // 导入 CSS 文件
import hourses from '../assets/login/hourses.png'; // 导入图片
import grades from '../assets/login/grades.png'; // 导入图片
import shops from '../assets/login/shops.png'; // 导入图片
import nopay from '../assets/login/nopay.png'; // 导入图片
import nohand from '../assets/login/nohand.png'; // 导入图片
import nosay from '../assets/login/nosay.png'; // 导入图片
import yesok from '../assets/login/yesok.png'; // 导入图片
import backed from '../assets/login/backed.png'; // 导入图片
import pack from '../assets/login/pack.png'; // 导入图片
import couple from '../assets/login/couple.png'; // 导入图片
import address from '../assets/login/address.png'; // 导入图片
import good from '../assets/login/good.png'; // 导入图片
import heartl from '../assets/login/heartl.png'; // 导入图片
import heartr from '../assets/login/heartr.png'; // 导入图片
import my from '../assets/login/my.png'; // 导入图片
import head from '../assets/login/head.jpg'; // 导入图片

const My = () => {

    const handleNavigateToNopay = () => {
      navigate('/all?tab=nopay');  // 跳转到带有 tab=nopay 的 URL
    };
    const handleNavigateToNohand = () => {
        navigate('/all?tab=nohand');  // 跳转到带有 tab=nopay 的 URL
      };
    const [products, setProducts] = useState([]); // 存储商品列表
    const [cartItems, setCartItems] = useState([]); // 存储购物车商品数据
    const navigate = useNavigate(); // 初始化 navigate
    const handleAddToCart = (productId) => {
        console.log(productId);

        // 获取用户信息
        const userId = localStorage.getItem('userid');
        if (!userId) {
            Toast.show('用户信息缺失');
            return;
        }

        // 查找当前商品
        const product = products.find(item => item.id === productId);
        if (!product) {
            Toast.show('商品信息缺失');
            return;
        }

        // 检查购物车中是否已有此商品
        const existingProductIndex = cartItems.findIndex(item => item.id === productId);
        if (existingProductIndex !== -1) {
            // 如果商品已存在，增加数量
            const updatedCartItems = [...cartItems];
            updatedCartItems[existingProductIndex].quantity += 1; // 增加数量
            setCartItems(updatedCartItems);
        } else {
            // 如果商品不在购物车中，添加商品到购物车
            const newProduct = { ...product, quantity: 1 }; // 新商品的初始数量
            setCartItems((prevCartItems) => [...prevCartItems, newProduct]); // 添加新商品
        }
    };
    // 使用 useEffect 获取商品数据
    useEffect(() => {
        fetch('http://jingxun.kuxia.top/mobile/index', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                key1: 'value1',
                key2: 'value2',
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                const commodityData = data?.data?.commodity;
                if (commodityData && Array.isArray(commodityData)) {
                    setProducts(commodityData);
                }
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);
    // 处理点击商品，跳转到商品详情页
    const handleProductClick = (product) => {
        navigate('/detail', { state: { product } }); // 通过 state 传递商品数据
    };
    return (
        <div className={mys.mybig}>
            {/* 头*/}
            <div className={mys.myback}>
                <img src={myback} alt="" />
                <div className={mys.heads}>
                    <img src={head} alt="" />
                    <div>
                        <p>546v</p>
                        <div className={mys.myid}>ID:200412</div>
                    </div>
                </div>
            </div>
            {/* 余额 */}
            <div className={mys.moneys}>
                <div className={mys.bigmoney}>
                    <div className={mys.moneys_item}>
                        <div className={mys.money}>
                            ￥1243.23
                        </div>
                        <div className={mys.money_font}>
                            余额（元）
                        </div>
                    </div>
                    <div className={mys.moneys_item}>
                        <div className={mys.money}>
                            ￥3314
                        </div>
                        <div className={mys.money_font}>
                            总收益（元）
                        </div>
                    </div>
                    <div className={mys.moneys_item}>
                        <div className={mys.money}>
                            ￥741
                        </div>
                        <div className={mys.money_font}>
                            可用收益（元）
                        </div>
                    </div>
                </div>
            </div>
            {/* 我的订单 */}
            <div className={mys.icons}>
                <div className={mys.myfont}>
                    <div className={mys.font_item}>我的订单</div>
                    <div  className={mys.font_items} onClick={() => navigate('/all')}>全部订单</div>
                </div>
                <div class={mys.icon_container}>
                    <div class={mys.icon_item} onClick={handleNavigateToNopay}>
                        <img src={nopay} alt="待付款" />
                        <p>待付款</p>
                    </div>
                    <div class={mys.icon_item} onClick={handleNavigateToNohand}>
                        <img src={nohand} alt="待收货" />
                        <p>待收货</p>
                    </div>
                    <div class={mys.icon_item}>
                        <img src={nosay} alt="待评价" />
                        <p>待评价</p>
                    </div>
                    <div class={mys.icon_item}>
                        <img src={yesok} alt="已完成" />
                        <p>已完成</p>
                    </div>
                    <div class={mys.icon_item}>
                        <img src={backed} alt="退换货" />
                        <p>退换货</p>
                    </div>
                </div>
            </div>
            {/* 我的服务 */}
            <div className={mys.icons}>
                <div className={mys.myfont}>
                    <div className={mys.font_item}>我的服务</div>
                </div>
                <div class={mys.icon_container}>
                    <div class={mys.icon_item}>
                        <img src={pack} alt="待收货" />
                        <p>我的钱包</p>
                    </div>
                    <div class={mys.icon_item}>
                        <img src={couple} alt="待评价" />
                        <p>分销中心</p>
                    </div>
                    <div class={mys.icon_item}>
                        <img src={address} alt="已完成" />
                        <p>收货地址</p>
                    </div>
                    <div class={mys.icon_item}>
                        <img src={good} alt="退换货" />
                        <p>邀请好友</p>
                    </div>
                </div>
            </div>
            <div className={cars.guess}>
                <div className={cars.guess_item}>
                    <img src={heartr} alt="" />
                    <div>猜你喜欢</div>
                    <img src={heartl} alt="" />
                </div>
            </div>

            <div className={homes.product_list}>
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <div key={index} className={homes.product}>
                            <img onClick={() => handleProductClick(product)}
                                src={`http://jingxun.kuxia.top${product.image}`}
                                alt={product.title}
                                className={homes.product_image}
                            />
                            <div className={homes.product_info}>
                                <p>{product.title}</p>
                                <div className={homes.cell}>
                                    <p className={homes.price}>￥{product.price || '未知'}</p>
                                    <div className={homes.add} onClick={() => handleAddToCart(product.id)}>
                                        <img src={add} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>加载中...</p>
                )}
            </div>
            {/* 底部导航栏 */}
            <div className={homes.bottom_nav}>
                <div className={homes.nav_item} onClick={() => navigate('/home')}>
                    <img src={hourses} alt="首页" className={homes.nav_icon} />
                    <span>首页</span>
                </div>
                <div className={homes.nav_item}>
                    <img src={grades} alt="分类" className={homes.nav_icon} />
                    <span>分类</span>
                </div>
                <div className={homes.nav_item} onClick={() => navigate('/car')}>
                    <img src={shops} alt="购物车" className={homes.nav_icon} />
                    <span>购物车</span>
                </div>
                <div className={homes.nav_item} onClick={() => navigate('/my')}>
                    <img src={my} alt="我的" className={homes.nav_icon} />
                    <span style={{ color: '#E3B67E' }}>我的</span>
                </div>
            </div>
        </div>

    );
};

export default My;
