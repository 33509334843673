import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd-mobile';
import alls from '../css/all.module.css';  // 导入 CSS 文件
import ret from '../assets/login/ret.png';  // 返回按钮图片路径
import head from '../assets/login/head.jpg';  // 默认头像路径
import { useNavigate, useLocation } from 'react-router-dom';

export default function Orders() {
  const navigate = useNavigate();  // 用来进行路由跳转
  const location = useLocation();  // 获取当前的 URL
  const [orders, setOrders] = useState([]);  // 用来存储接口返回的订单数据
  const [loading, setLoading] = useState(false);  // 加载状态

  // 获取当前选中的 tab（从路由参数中获取）
  const queryParams = new URLSearchParams(location.search);
  const [activeTab,setActiveTab] =useState(queryParams.get('tab') || 'all');  // 默认显示 "全部" 标签

  // 返回上一页的函数
  const handleBack = () => {
    navigate(-1);  // 使用 -1 返回上一页
  };

  // 获取订单数据的函数
  const fetchOrders = async () => {
    const userId = sessionStorage.getItem('userid'); // 获取本地存储中的userid
    setLoading(true); // 开始加载

    if (userId) {
      try {
        const response = await fetch('http://jingxun.kuxia.top/mobile/order', {
          method: 'POST',  // 使用 POST 请求
          headers: {
            'Content-Type': 'application/json',  // 发送 JSON 数据
          },
          body: JSON.stringify({ id: userId }),  // 将用户id作为请求体发送
        });

        const data = await response.json();
console.log(data);

        if (data.code === 1) {
          setOrders(data.data);  // 设置订单数据
        } else {
          console.error('请求失败:', data.msg);
        }
      } catch (error) {
        console.error('请求失败:', error);
      }
    } else {
      console.log('用户ID不存在');
    }

    setLoading(false);  // 完成加载
  };
// tab
const tabchange=(key)=>{
  
}
  // 在组件加载时发起请求
  useEffect(() => {
    fetchOrders();
    setActiveTab(queryParams.get('tab') || 'all')
  }, []); // 空依赖数组表示只会在组件加载时执行一次

  // 根据当前选中的tab渲染订单项
  const renderOrder = (order) => {
    console.log(order);
    
    const { order: orderId, commodity, price } = order;  // 获取订单信息
    const product = commodity && commodity[0];  // 假设 commodity 是一个数组，取第一个商品

    const imageUrl = product ? `http://jingxun.kuxia.top${product.image}` : head;  // 拼接图片前缀

    // 获取商品的状态
    const orderState = product ? product.state : 0;  // 获取商品的状态，默认0（如果没有找到状态）

    // 状态映射对象
    const orderStatus = {
      1: '待付款',
      2: '待收货',
      3: '已完成',
      4: '已取消'
    };

    return (
      <div key={orderId} className={alls.card}>
        <div className={alls.num}>订单编号：{orderId}</div>
        <div className={alls.line}></div>
        <div className={alls.list}>
          <img src={imageUrl} alt="商品" />  {/* 使用拼接后的图片 URL */}
          <div className={alls.name}>{product ? product.title : '商品名称'}</div>
          <p>{orderStatus[orderState] || '未知状态'}</p>  {/* 状态映射 */}
        </div>
        <div className={alls.line}></div>
        <div className={alls.pay}>
          <div className={alls.should}>应付￥{product.price}</div>
          <div className={alls.payright}>
            <div className={alls.rightcancel}>取消订单</div>
            <div className={alls.rightpay}>付款</div>
          </div>
        </div>
      </div>
    );
  };

  // 如果没有订单数据，显示提示信息
  const renderEmpty = () => (
    <div className={alls.empty}>暂无订单</div>
  );

  // 根据当前 tab 渲染过滤后的订单列表
  const filteredOrders = (state) => {
    if (state === 'all') return orders;
    return orders.filter(order => order.commodity[0]?.state === parseInt(state));
  };

  return (
    <div className={alls.big}>
      <div className={alls.head}>
        <div onClick={handleBack} className={alls.ret}>
          <img src={ret} alt="返回" />
        </div>
        <p>我的订单</p>
      </div>

      {/* 渲染 Tabs 组件 */}
      <Tabs
        activeKey={activeTab}  // 控制当前激活的 tab
        onChange={(key) => 
          // 跳转到当前选项卡对应的路由
          // navigate(`/orders?tab=${key}`);
          setActiveTab(key)
        }
        style={{
          '--active-line-color': 'rgba(224, 175, 117, 1)',
          '--active-title-color': 'rgba(224, 175, 117, 1)',
          '--title-font-size': '15px',
        }}
      >
        <Tabs.Tab title="全部" key="all">
          {filteredOrders('all').length > 0 ? filteredOrders('all').map((order) => renderOrder(order)) : renderEmpty()}
        </Tabs.Tab>
        <Tabs.Tab title="待付款" key="nopay">
          {filteredOrders('1').length > 0 ? filteredOrders('1').map((order) => renderOrder(order)) : renderEmpty()}
        </Tabs.Tab>
        <Tabs.Tab title="待收货" key="nohand">
          {filteredOrders('2').length > 0 ? filteredOrders('2').map((order) => renderOrder(order)) : renderEmpty()}
        </Tabs.Tab>
        <Tabs.Tab title="已完成" key="yesok">
          {filteredOrders('3').length > 0 ? filteredOrders('3').map((order) => renderOrder(order)) : renderEmpty()}
        </Tabs.Tab>
        <Tabs.Tab title="已取消" key="cancel">
          {filteredOrders('4').length > 0 ? filteredOrders('4').map((order) => renderOrder(order)) : renderEmpty()}
        </Tabs.Tab>
      </Tabs>
    </div>
  );
}
