import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd-mobile'; // 导入 Tabs 组件
import ret from '../assets/login/ret.png'; // 确保图片路径正确
import hourse from '../assets/login/hourse.png'; // 确保图片路径正确
import details from '../css/detail.module.css'; // 导入 CSS 文件
import { Swiper } from 'antd-mobile';

const TabExample = () => {
  const location = useLocation();
  const navigate = useNavigate();  // 用来进行路由跳转
  const product = location.state?.product; // 获取传递的 product 数据

  // 打印传递过来的 product 数据
  console.log(product);

  // 返回上一页的函数
  const handleBack = () => {
    navigate(-1); // 使用 -1 返回上一页
  };

  // 基础图片路径
  const baseImageUrl = 'http://jingxun.kuxia.top';

  // 将 `banner` 字段的图片路径字符串拆分成数组
  const bannerImages = product?.banner ? product.banner.split(',') : [];

  // 生成每个 Swiper.Item 来展示图片
  const items = bannerImages.map((image, index) => (
    <Swiper.Item key={index}>
      <div className={details.content}>
        <img
          src={`${baseImageUrl}${image}`}
          alt={`商品图片 ${index + 1}`}
          className={details.bannerImg}
        />
      </div>
    </Swiper.Item>
  ));

  return (
    <div className={details.alls} style={{ padding: '10px' }}>
      {/* 包裹返回按钮和 Tabs 的 div，使用 flex 布局 */}
      <div className={details.top} style={{ display: 'flex' }}>
        {/* 返回按钮 */}
        <div onClick={handleBack} className={details.ret}>
          <img src={ret} alt="返回" />
        </div>

        {/* Tabs 组件，设置高亮颜色为黄色 */}
        <Tabs style={{ '--active-line-color': 'rgba(224, 175, 117, 1)', '--active-title-color': 'rgba(224, 175, 117, 1)', '--title-font-size': '15px' }} className={details.titles}>
          <Tabs.Tab title="商品" key="goods">
            <div className={details.first}>
              {/* 商品图片前拼接基础 URL，展示走马灯效果 */}
              <Swiper
                loop
                autoplay
                onIndexChange={i => {
                  console.log(i, 'onIndexChange1');
                }}
              >
                {items}
              </Swiper>

              {/* 商品名称渲染为 product.title */}
              <div className={details.first_title}>{product?.title || '商品名称'}</div>

              {/* 商品价格渲染为 product.price */}
              <div className={details.price}>￥{product?.price || '0.00'}</div>
            </div>
          </Tabs.Tab>
          <Tabs.Tab title="详情" key="detail">
            {/* 商品详情渲染为 product.content */}
            {product?.content || '暂无商品详情'}
          </Tabs.Tab>
          <Tabs.Tab title="评价" key="cap">
            {/* 商品评价渲染为 product.reviews */}
            {product?.reviews || '暂无商品评价'}
          </Tabs.Tab>
        </Tabs>
      </div>

            {/* 底部导航栏 */}
            {/* <div className={details.foot}>
              <div className={details.foot_one}>
                <div className={details.foot_shou}>
                  <img src={hourse} />
                  <div className={details.foot_text}>首页</div>
                </div>
                <div className={details.foot_shou}>
                  <img src={ret} />
                  <div className={details.foot_text}>购物车</div>
                </div>
              </div>
              <div className={details.foot_two} >加入购物车</div>
              <div className={details.foot_three}>立即购买</div>
            </div> */}
    </div>
  );
};

export default TabExample;
