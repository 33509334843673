import React, { useState } from "react";
import { Button, Toast } from 'antd-mobile';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate
import imgs from '../assets/login/wx.png'; // 确保图片路径正确
import '../css/login.css'; // 导入 CSS 文件

export default function Login() {
    // 使用 useState 来管理输入框的值
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate(); // 创建 navigate 函数，用于跳转

    // 点击登录按钮时的处理函数
    const handleLogin = async () => {
        // 判断账号是否为空
        if (!username) {
            Toast.show({
                content: '请输入账号！',
                duration: 2000,
                position: 'top',
            });
            return; // 终止后续操作
        }

        // 判断密码是否为空
        if (!password) {
            Toast.show({
                content: '请输入密码！',
                duration: 2000,
                position: 'top',
            });
            return; // 终止后续操作
        }

        // 如果账号和密码都不为空，执行登录逻辑
        try {
            const response = await fetch('http://jingxun.kuxia.top/mobile/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    tel: username,
                    password: password,
                }),
            });

            const data = await response.json();

            if (response.ok && data.code === 1) {
                // 登录成功并且 code 为 1
                Toast.show({
                    content: '登录成功！',
                    duration: 2000,
                    position: 'top',
                    style: {
                        backgroundColor: '#28a745', // 绿色背景
                        color: 'white',
                        borderRadius: '8px',
                    },
                });

                // 打印用户信息
                console.log('登录成功，用户信息:', data); // 假设 data 中有 userInfo 字段

                // 将 user ID 存储到 localStorage 中
                const userId = data.data.id;
                const tel = data.data.tel;
                const password = data.data.password;

                sessionStorage.setItem('userid', userId); // 存储用户 ID
                localStorage.setItem('tel', tel); // 存储用户 ID
                localStorage.setItem('password', password); // 存储用户 ID


                // 登录成功后跳转到 Home 页面
                navigate('/home');
            } else {
                // 登录失败，显示错误信息
                Toast.show({
                    content: data.message || '账号或密码输入错误',
                    duration: 2000,
                    position: 'top',
                    style: {
                        backgroundColor: '#f44336', // 红色背景
                        color: 'white',
                        borderRadius: '8px',
                    },
                });
            }
        } catch (error) {
            // 网络错误等异常情况
            Toast.show({
                content: '网络错误，请稍后再试。',
                duration: 2000,
                position: 'top',
                style: {
                    backgroundColor: '#f44336', // 红色背景
                    color: 'white',
                    borderRadius: '8px',
                },
            });
            console.error('登录请求失败:', error);
        }
    };

    return (
        <div className="big">
            <div className="font">登录</div>
            {/* 账号密码输入框 */}
            <div className="inputs">
                <input
                    type="text"
                    placeholder="请输入手机号/用户名/邮箱"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)} // 更新用户名
                />
                <div className="line"></div>
                <input
                    className="pass"
                    type="password"
                    placeholder="请输入密码"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // 更新密码
                />
                <div className="line"></div>
                <div className="message">
                    <div>忘记密码</div>
                    <div>免密码登录</div>
                </div>
                <div className="bottom">
                    <Button className="login" onClick={handleLogin}>登录</Button>
                    <div className="go">没有账号，去注册</div>
                    <div className="three">第三方账号登录</div>
                    <img className="pic" src={imgs} alt="微信登录" />
                    <div className="three">登录即同意《用户服务协议》</div>
                </div>
            </div>
        </div>
    );
}
