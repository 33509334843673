import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import homes from '../css/home.module.css'; // 导入 CSS 文件
import hourses from '../assets/login/hourses.png'; // 导入图片
import grades from '../assets/login/grades.png'; // 导入图片
import shop from '../assets/login/shop.png'; // 导入图片
import mys from '../assets/login/mys.png'; // 导入图片
import heartl from '../assets/login/heartl.png'; // 导入图片
import heartr from '../assets/login/heartr.png'; // 导入图片
import add from '../assets/login/add.png'; // 导入图片
import cars from '../css/car.module.css'; // 导入 CSS 文件
import { Checkbox, Space } from 'antd-mobile';
import {
    Form,  // 导入 Form 组件
    Stepper,  // 导入 Stepper 组件
    Toast,  // 导入 Toast 提示组件
} from 'antd-mobile';

function Car() {
    const [products, setProducts] = useState([]); // 存储商品列表
    const [cartItems, setCartItems] = useState([]); // 存储购物车商品数据
    const [selectedItems, setSelectedItems] = useState([]); // 存储选中的商品ID
    const [isSelectAll, setIsSelectAll] = useState(false); // 控制全选状态
    const navigate = useNavigate(); // 初始化 navigate
    const [comply, setcompyl] = useState([]);

    // 使用 useEffect 获取商品数据
    useEffect(() => {
        fetch('http://jingxun.kuxia.top/mobile/index', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                key1: 'value1',
                key2: 'value2',
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                const commodityData = data?.data?.commodity;
                if (commodityData && Array.isArray(commodityData)) {
                    setProducts(commodityData);
                }
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);
    // 使用 useEffect 获取购物车数据
    useEffect(() => {
        const id = sessionStorage.getItem('userid');
        console.log(id);

        if (id) {
            const requestData = { id: id };
            fetch('http://jingxun.kuxia.top/mobile/shopping', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data.data);
                    setcompyl(data.data)

                    if (data && data.code === 1 && data.data && data.data.length > 0) {
                        // 合并相同 commodity_id 的商品
                        const mergedCommodities = data.data.reduce((acc, item) => {
                           
                                // 如果商品不存在，添加到购物车
                                acc.push({
                                    ...item.commodity[0], // 获取商品信息
                                    quantity: item.num, // 初始数量为商品的 num 字段
                                });
                            
                            return acc;
                        }, []);

                        // 设置购物车商品
                        console.log(mergedCommodities);
                        
                        setCartItems(mergedCommodities);
                    }
                })
                .catch((error) => console.error('获取购物车数据时出错:', error));
        }
    }, []);

    // 处理商品数量更新，不再限制最大数量
    const handleQuantityChange = (productId, quantity) => {
        setCartItems((prevItems) =>
            prevItems.map((item) =>
                item.id === productId ? { ...item, quantity: Math.min(quantity, item.num) } : item
            )
        );
    };

    // 处理点击商品，跳转到商品详情页
    const handleProductClick = (product) => {
        navigate('/detail', { state: { product } }); // 通过 state 传递商品数据
    };

    // 处理单个商品复选框的选中状态
    const handleSelectItem = (productId) => {
        setSelectedItems((prevSelectedItems) =>
            prevSelectedItems.includes(productId)
                ? prevSelectedItems.filter(id => id !== productId)
                : [...prevSelectedItems, productId]
        );
    };

    // 处理全选复选框的选中状态
    const handleSelectAll = () => {
        if (isSelectAll) {
            setSelectedItems([]); // 清空选中的商品
        } else {
            setSelectedItems(cartItems.map(item => item.id)); // 全选商品
        }
        setIsSelectAll(!isSelectAll); // 更新全选状态
    };

    // 计算选中的商品总金额
    const calculateTotal = () => {
        if (selectedItems.length === 0) {
            return 0;
        }
        return cartItems
            .filter(item => selectedItems.includes(item.id))
            .reduce((total, item) => total + (item.price || 0) * item.quantity, 0);
    };
    // 处理添加商品到购物车
    const handleAddToCart = async (productId) => {
        console.log('Product ID:', productId.id);  // 打印传入的商品 ID
        const userId = sessionStorage.getItem('userid');
        console.log('User ID:', userId);  // 打印用户 ID
    
        // 判断商品是否已经在购物车中
        let itemExists = false;
    
        // 遍历 comply 数据检查商品是否已经在购物车中
        for (const cartItem of comply) {
            for (const commodity of cartItem.commodity) {
                console.log('Checking cartItem:', cartItem);
    
                // 对比商品 ID 是否匹配
                if (commodity.id === productId.id) {
                    console.log('商品已存在:', commodity.id, productId.id);
    
                    itemExists = true;
    
                    const requestData = { user_id: userId, id: cartItem.id, num: cartItem.num + 1 };
                    console.log('请求数据:', requestData); // 打印请求数据
    
                    try {
                        const response = await fetch('http://jingxun.kuxia.top/mobile/shopping/edit', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(requestData),
                        });
    
                        const result = await response.json();
                        console.log('接口返回结果:', result);
    
                        if (response.ok) {
                            console.log('更新成功', result);
                            fetchCartData(); // 调用重新加载购物车数据的函数
                        } else {
                            console.error('更新失败', result.message || '请求失败');
                        }
    
                    } catch (error) {
                        console.error('请求错误:', error);
                    }
    
                    break;  // 如果找到了商品，就不再继续循环
                }
            }
        }
    
        // 如果商品不在购物车中，执行添加商品到购物车的操作
        if (!itemExists) {
            const requestData = { user_id: userId, commodity_id: productId.id, num: 1 };
            console.log('商品未找到，执行添加操作:', requestData);
    
            try {
                const response = await fetch('http://jingxun.kuxia.top/mobile/shopping/add', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestData),
                });
    
                const result = await response.json();
                console.log('接口返回结果:', result);
                if (response.ok) {
                    console.log('商品已添加到购物车', result);
                    fetchCartData(); // 调用重新加载购物车数据的函数
                } else {
                    console.error('添加失败', result.message || '请求失败');
                }
    
            } catch (error) {
                console.error('请求错误:', error);
            }
        }
    };
    
    // 重新加载购物车数据的函数
    const fetchCartData = () => {
        const userId = sessionStorage.getItem('userid');
        console.log('重新获取购物车数据 User ID:', userId);
    
        if (userId) {
            const requestData = { id: userId };
            fetch('http://jingxun.kuxia.top/mobile/shopping', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data.data);
                    setcompyl(data.data); // 更新 comply 数据
    
                    if (data && data.code === 1 && data.data && data.data.length > 0) {
                        // 合并相同 commodity_id 的商品
                        const mergedCommodities = data.data.reduce((acc, item) => {
                            const existingItem = acc.find(
                                (existing) => existing.commodity_id === item.commodity_id
                            );
                            if (existingItem) {
                                // 如果商品已经存在，合并数量
                                existingItem.quantity += item.num;
                            } else {
                                // 如果商品不存在，添加到购物车
                                acc.push({
                                    ...item.commodity[0], // 获取商品信息
                                    quantity: item.num, // 初始数量为商品的 num 字段
                                });
                            }
                            return acc;
                        }, []);
    
                        // 更新购物车商品
                        console.log(mergedCommodities);
                        setCartItems(mergedCommodities);
                        setcompyl(mergedCommodities); // 确保 `comply` 数据也同步更新
                    }
                })
                .catch((error) => console.error('获取购物车数据时出错:', error));
        }
    };
    




    return (
        <div className={cars.big}>
            <div className={cars.head}>购物车</div>
            <div className={cars.frame}>
                {cartItems.length > 0 ? (
                    cartItems.map((item, index) => (
                        <div key={index} className={cars.shops}>
                            <div className={cars.mui_input_row}>
                                <label></label>
                                <Checkbox
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() => handleSelectItem(item.id)}
                                />
                            </div>
                            <div className={cars.pic}>
                                <img src={`http://jingxun.kuxia.top${item.image}`} alt={item.title} />
                            </div>

                            <div className={cars.details}>
                                <div className={cars.name}>{item.title}</div>

                                <div className={cars.num}>
                                    <div className={cars.price}>￥{item.price}</div>

                                    {/* 渲染商品数量输入框，设置最大数量为商品的 num 字段 */}
                                    <Form.Item
                                        style={{ marginTop: '-12px', marginLeft: '-12px' }}
                                        initialValue={item.quantity || 1}
                                        rules={[
                                            {
                                                min: 1,
                                                type: 'number',
                                            },
                                        ]}
                                        name='stepper-demo'
                                    >
                                        <Stepper
                                        defaultValue={item?.quantity||1}
                                            // defaultValue={item.quantity || 1}  // 使用 item.quantity 来动态显示当前数量
                                            onChange={(val) => handleQuantityChange(item.id, val)}  // 调用 handleQuantityChange 更新数量
                                            max={item.num}  // 将 item.num 设置为最大数量限制
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={cars.emptyCart}>购物车为空</div>
                )}
            </div>
            <div className={cars.guess}>
                <div className={cars.guess_item}>
                    <img src={heartr} alt="" />
                    <div>猜你喜欢</div>
                    <img src={heartl} alt="" />
                </div>
            </div>

            <div className={homes.product_list}>
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <div key={index} className={homes.product}>
                            <img onClick={() => handleProductClick(product)}
                                src={`http://jingxun.kuxia.top${product.image}`}
                                alt={product.title}
                                className={homes.product_image}
                            />
                            <div className={homes.product_info}>
                                <p>{product.title}</p>
                                <div className={homes.cell}>
                                    <p className={homes.price}>￥{product.price || '未知'}</p>
                                    <div className={homes.add} onClick={() => handleAddToCart(product)}>
                                        <img src={add} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>加载中...</p>
                )}
            </div>
            {/* 复选框及结算区域 */}
            <div className={cars.bot}>
                <div className={cars.bottomBar}>

                    <Checkbox style={{ fontSize: '10px' }}
                        indeterminate={selectedItems.length > 0 && selectedItems.length < cartItems.length}
                        checked={selectedItems.length === cartItems.length}
                        onChange={handleSelectAll}
                    >
                        <div style={{ fontSize: '13px' }}>全选</div>
                    </Checkbox>
                    <div style={{ whiteSpace: 'nowrap' }} className={cars.selectAll}>
                        <span style={{ color: 'red' }}>合计：</span>
                        <span style={{ whiteSpace: 'nowrap', color: 'red' }}>￥{calculateTotal().toFixed(2)}</span>
                    </div>
                    <div className={cars.checkoutButton}>
                        <div>结算</div>
                    </div>
                </div>
            </div>

            {/* 底部导航栏 */}
            <div className={homes.bottom_nav}>
                <div className={homes.nav_item} onClick={() => navigate('/home')}>
                    <img src={hourses} alt="首页" className={homes.nav_icon} />
                    <span>首页</span>
                </div>
                <div className={homes.nav_item} onClick={() => navigate('/category')}>
                    <img src={grades} alt="分类" className={homes.nav_icon} />
                    <span>分类</span>
                </div>
                <div className={homes.nav_item}>
                    <img src={shop} alt="购物车" className={homes.nav_icon} />
                    <span style={{ color: '#E3B67E' }}>购物车</span>
                </div>
                <div className={homes.nav_item} onClick={() => navigate('/my')}>
                    <img src={mys} alt="我的" className={homes.nav_icon} />
                    <span>我的</span>
                </div>
            </div>
        </div>
    );
}

export default Car;