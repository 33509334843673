import React, { useEffect, useState } from 'react'; // 导入 useEffect 和 useState
import { useNavigate } from 'react-router-dom'; // 导入 useNavigate 来进行页面跳转
import homes from '../css/home.module.css'; // 导入 CSS 文件
import mirror from '../assets/login/mirror.png'; // 确保图片路径正确
import banner from '../assets/login/home.png'; // 确保图片路径正确
import beauty from '../assets/login/beauty.png'; // 确保图片路径正确
import boy from '../assets/login/boy.png'; // 确保图片路径正确
import girl from '../assets/login/girl.png'; // 确保图片路径正确
import food from '../assets/login/food.png'; // 确保图片路径正确
import dian from '../assets/login/dian.png'; // 确保图片路径正确
import family from '../assets/login/family.png'; // 确保图片路径正确
import mom from '../assets/login/mom.png'; // 确保图片路径正确
import fire from '../assets/login/fire.png'; // 确保图片路径正确
import add from '../assets/login/add.png'; // 确保图片路径正确
import right from '../assets/login/right.png'; // 确保图片路径正确
import milk from '../assets/login/milk.jpg'; // 确保图片路径正确
import hundred from '../assets/login/hundred.png'; // 确保图片路径正确
import hourse from '../assets/login/hourse.png'; // 确保图片路径正确
import grades from '../assets/login/grades.png'; // 确保图片路径正确
import shops from '../assets/login/shops.png'; // 确保图片路径正确
import mys from '../assets/login/mys.png'; // 确保图片路径正确
import { Swiper } from 'antd-mobile'
import {
  Form,  // 导入 Form 组件
  Stepper,  // 导入 Stepper 组件
  Toast,  // 导入 Toast 提示组件
} from 'antd-mobile';

function Home() {
  const [products, setProducts] = useState([]); // 商品列表
  const [hotProducts, setHotProducts] = useState([]); // 热门商品列表
  const [slideshow, setSlideshow] = useState([]); // 轮播图数据
  const navigate = useNavigate(); // 初始化 navigate
  const [cartItems, setCartItems] = useState([]); // 存储购物车商品数据

  // 轮播图项
  const items = slideshow.map((item, index) => (
    <Swiper.Item key={index}>
      <div className={homes.content}>
        <img
          src={`http://jingxun.kuxia.top${item.banner}`}
          alt={item.title}
          className={homes.bannerImg}
        />
      </div>
    </Swiper.Item>
  ));

  // 获取数据
  useEffect(() => {
    const requestData = {
      key1: 'value1', // 假设发送的请求数据，具体内容根据 API 需求而定
      key2: 'value2',
    };

    fetch('http://jingxun.kuxia.top/mobile/index', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // 获取 banner 数据并更新状态
        const bannerData = data?.data?.banner;
        if (bannerData && Array.isArray(bannerData)) {
          setSlideshow(bannerData); // 将 banner 数据存入 slideshow
        }

        const commodityData = data?.data?.commodity;
        const hotData = data?.data?.hot;

        if (commodityData && Array.isArray(commodityData)) {
          setProducts(commodityData); // 更新商品列表
        }

        if (hotData && Array.isArray(hotData)) {
          setHotProducts(hotData); // 更新热门商品列表
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // 商品点击事件，跳转到详情页
  const handleProductClick = (product) => {
    navigate('/detail', { state: { product } }); // 传递商品数据到详情页
  };

  // 跳转到购物车
  const goToCart = () => {
    navigate('/car'); // 假设购物车页面路径是 '/car'
  };
  // 添加到購物車
  const handleAddToCart = (productId) => {
    console.log("尝试添加商品ID:", productId);
    
    // 获取用户信息
    const userId = localStorage.getItem('userid');
    if (!userId) {
        Toast.show('用户信息缺失');
        return;
    }
    
    // 查找当前商品
    const product = products.find(item => item.id === productId);
    if (!product) {
        Toast.show('商品信息缺失');
        return;
    }
    
    // 构建请求数据
    const requestData = {
        user_id: userId,
        commodity_id: productId,
        num: 1 // 这里假设每次添加的商品数量是1
    };
    
    // 向服务器发送添加商品到购物车的请求
    fetch('http://jingxun.kuxia.top/mobile/shopping/add', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
    })
    .then((response) => response.json())
    .then((data) => {
        if (data.code === 1) {
            // 如果添加成功，更新购物车状态
            const existingProductIndex = cartItems.findIndex(item => item.id === productId);
            
            if (existingProductIndex !== -1) {
                // 如果商品已存在，增加数量
                const updatedCartItems = cartItems.map((item, index) =>
                    index === existingProductIndex
                        ? { ...item, quantity: item.quantity + 1 }  // 增加数量
                        : item
                );
                setCartItems(updatedCartItems);
            } else {
                // 如果商品不在购物车中，添加商品到购物车
                const newProduct = { ...product, quantity: 1 }; // 新商品的初始数量为1
                setCartItems(prevCartItems => [...prevCartItems, newProduct]); // 将新商品添加到购物车
            }

            // 显示添加成功的提示
            Toast.show('已成功添加到购物车');
            console.log("当前购物车数据:", cartItems);
        } else {
            // 如果添加失败，显示失败的提示
            Toast.show('添加商品到购物车失败');
        }
    })
    .catch((error) => {
        // 错误处理
        console.error('添加商品到购物车时出错:', error);
        Toast.show('请求失败，请稍后再试');
    });
};
  
  return (
    <div className={homes.all}>
      <div className={homes.first}>
        <div className={homes.search}>
          <img className={homes.mirror} src={mirror} alt="" />
          <input className={homes.inp} type="text" />
          <div className={homes.searchBut}>搜索</div>
        </div>
        <div className={homes.imgs}>
          <Swiper
            loop
            autoplay
            onIndexChange={i => {
              // console.log(i, 'onIndexChange1')
            }}
          >
            {items}
          </Swiper>
        </div>
        <div className={homes.shop_icons_container}>
          <div className={homes.row}>
            <div className={homes.icon_item}>
              <img src={beauty} alt="美妆" className={homes.icon_image} />
              <div className={homes.icon_text}>美妆</div>
            </div>
            <div className={homes.icon_item}>
              <img src={boy} alt="男装" className={homes.icon_image} />
              <div className={homes.icon_text}>男装</div>
            </div>
            <div className={homes.icon_item}>
              <img src={beauty} alt="女妆" className={homes.icon_image} />
              <div className={homes.icon_text}>女妆</div>
            </div>
            <div className={homes.icon_item}>
              <img src={food} alt="食品" className={homes.icon_image} />
              <div className={homes.icon_text}>食品</div>
            </div>
          </div>
          <div className={homes.row}>
            <div className={homes.icon_item}>
              <img src={dian} alt="电器" className={homes.icon_image} />
              <div className={homes.icon_text}>电器</div>
            </div>
            <div className={homes.icon_item}>
              <img src={family} alt="家具" className={homes.icon_image} />
              <div className={homes.icon_text}>家具</div>
            </div>
            <div className={homes.icon_item}>
              <img src={mom} alt="母婴" className={homes.icon_image} />
              <div className={homes.icon_text}>母婴</div>
            </div>
            <div className={homes.icon_item}>
              <img src={hundred} alt="百货" className={homes.icon_image} />
              <div className={homes.icon_text}>百货</div>
            </div>
          </div>
        </div>
      </div>
      {/* 热门榜单 */}
      <div className={homes.second}>
        <div className={homes.hot}>
          <img src={fire} alt="" />
          <div className={homes.door}>热门榜单</div>
          <div className={homes.more}>
            <p>更多</p><img src={right} alt="" />
          </div>
        </div>
        <div className={homes.goods}>
          {/* 热门榜单商品数据渲染 */}
          {hotProducts.length > 0 ? (
            hotProducts.map((product, index) => (
              <div key={index} className={homes.good}>
                {/* 渲染热门商品的banner */}
                <img src={`http://jingxun.kuxia.top${product.image}`} alt={product.title} />
                <div className={homes.good_title}>{product.title}</div>
                {/* 假设商品价格字段为 price */}
                <div className={homes.price}>￥{product.price || '未知'}</div>
              </div>
            ))
          ) : (
            <p>加载中...</p> // 如果没有热门榜单数据则显示加载中
          )}
        </div>
      </div>
      {/* 第三行 */}
      <div className={homes.thirty}>
        <div>猜你喜欢</div>
        <div>美妆</div>
        <div>男装</div>
        <div>女装</div>
        <div>食品</div>
        <div>电器</div>
      </div>

      {/* 商品列表 */}
      <div className={homes.product_list}>
        {products.length > 0 ? (
          products.map((product, index) => (
            <div key={index} className={homes.product}>
              <img onClick={() => handleProductClick(product)}
                src={`http://jingxun.kuxia.top${product.image}`}
                alt={product.title}
                className={homes.product_image}
              />
              <div className={homes.product_info}>
                <p>{product.title}</p>
                <div className={homes.cell}>
                  <p className={homes.price}>￥{product.price || '未知'}</p>
                  <div className={homes.add} onClick={() => handleAddToCart(product.id)}>
                    <img src={add} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>加载中...</p>
        )}
      </div>
      {/* 底部导航栏 */}
      <div className={homes.bottom_nav}>
        <div className={homes.nav_item}>
          <img src={hourse} alt="首页" className={homes.nav_icon} />
          <span style={{ color: '#E3B67E' }}>首页</span>
        </div>
        <div className={homes.nav_item}>
          <img src={grades} alt="分类" className={homes.nav_icon} />
          <span>分类</span>
        </div>
        <div className={homes.nav_item} onClick={goToCart}>
          <img src={shops} alt="购物车" className={homes.nav_icon} />
          <span>购物车</span>
        </div>
        <div className={homes.nav_item} onClick={() => navigate('/my')}>
          <img src={mys} alt="我的" className={homes.nav_icon} />
          <span>我的</span>
        </div>
      </div>

    </div>
  );
}

export default Home;
