import { useRoutes } from 'react-router-dom';
import routes from './router';
 
function App() {
  return (
    <div>
      {/* 注册路由 */}
      {useRoutes(routes)}
    </div>
  );
}
 
export default App;